//     This code was generated by a Reinforced.Typings tool. 
//     Changes to this file may cause incorrect behavior and will be lost if
//     the code is regenerated.

export enum AppFunctions { 
	EditTenant = 12, 
	ReadFile = 20, 
	EditFile = 21, 
	ManipulateFile = 22, 
	ShowAppDocuments = 23, 
	ShowBackendDocuments = 24, 
	ManipulateMasterData = 29, 
	ReadContact = 30, 
	EditContact = 31, 
	ReadLocation = 40, 
	EditLocation = 41, 
	ReadAddress = 50, 
	EditAddress = 51, 
	ReadCompany = 60, 
	EditCompany = 61, 
	ReadInfo = 70, 
	EditInfo = 71, 
	ReadDevice = 80, 
	EditDevice = 81, 
	RegisterDevice = 82, 
	EditPing = 85, 
	EditTask = 90, 
	EditTaskJob = 91, 
	EditForm = 101, 
	EditFilledForm = 102, 
	ManipulateFilledForm = 103, 
	ReadKnowledgeArticle = 120, 
	EditKnowledgeArticle = 121, 
	ReadHoliday = 130, 
	EditHoliday = 131, 
	EditChat = 141, 
	ShowAppChat = 142, 
	ShowBackendChat = 143, 
	AllowChatPerEmail = 144, 
	ShowAllChatsPackage = 145, 
	ReadFolderAccess = 150, 
	EditFolderAccess = 151, 
	EditRole = 161, 
	ShowRoleEditor = 162, 
	EditHistory = 171, 
	ReadLoginChallenge = 180, 
	EditLoginChallenge = 181, 
	ReadLoginProvider = 182, 
	EditLoginProvider = 183, 
	EditProductSubscription = 184, 
	ReadStereotype = 200, 
	EditStereotype = 201, 
	ManipulateStereotype = 202, 
	ReadTicket = 210, 
	EditTicket = 211, 
	ForceTicketState = 212, 
	EditSettings = 220, 
	ReadGraph = 225, 
	ShowDashboard = 226, 
	ShowAppOverview = 227, 
	ReadExternalDocument = 230, 
	EditExternalDocument = 231, 
	EditExternalAllDocument = 232, 
	ReadResource = 240, 
	EditResource = 241, 
	ReadStateMachine = 250, 
	EditStateMachine = 251, 
	ReadDataTable = 260, 
	EditDataTable = 261, 
	ReadDashboard = 270, 
	EditDashboard = 271, 
	ReadPriority = 280, 
	EditPriority = 281, 
	ReadGuestCard = 290, 
	EditGuestCard = 291, 
	ReadEscalation = 300, 
	UseEscalation = 301, 
	EditEscalation = 302, 
	ReadAudit = 310, 
	ReadMission = 320, 
	EditMission = 321, 
	EditActualMissionDate = 322, 
	ReadLink = 330, 
	EditLink = 331, 
	ReadAttachmentForTech = 340, 
	EditAttachmentForTech = 341, 
	ReadTemplate = 350, 
	EditTemplate = 351, 
	ReadDocumentTemplate = 360, 
	EditDocumentTemplate = 361, 
	IsOrgaUser = 1000, 
	IsOrgaManager = 1001, 
	IsOrgaAdmin = 1002, 
	IsReseller = 1003, 
	IsAdmin = 1009, 
	IsSystemAdmin = 1010, 
	CanUseExpiredSubscription = 1011, 
	IsTechAdmin = 1012, 
	IsOrgaDashboardUser = 1013, 
	IsOrgaChatManager = 1014, 
	IsOrgaInfoManager = 1015, 
	IsOrgaBusiness = 2001, 
	IsOrgaEnterprise = 2002, 
	IsOrgaPremium = 2003, 
	IsOrgaCard = 2004, 
	IsTechEnterprise = 2011, 
	IsDataDefault = 2020
}
export enum AppPermissions { 
	ReadTicket = 10, 
	CreateTicket = 11, 
	UpdateTicket = 12, 
	DeleteTicket = 13, 
	ForceTicketState = 14, 
	ReadResource = 20, 
	CreateResource = 21, 
	UpdateResource = 22, 
	DeleteResource = 23, 
	ReadCustomProperty = 40, 
	CreateCustomProperty = 41, 
	UpdateCustomProperty = 42, 
	DeleteCustomProperty = 43, 
	ReadCustomPropertySet = 50, 
	CreateCustomPropertySet = 51, 
	UpdateCustomPropertySet = 52, 
	DeleteCustomPropertySet = 53, 
	ReadStereotype = 60, 
	CreateStereotype = 61, 
	UpdateStereotype = 62, 
	DeleteStereotype = 63, 
	ReadSetting = 70, 
	UpdateSetting = 71, 
	ReadCompany = 80, 
	CreateCompany = 81, 
	UpdateCompany = 82, 
	DeleteCompany = 83, 
	ReadLocation = 90, 
	CreateLocation = 91, 
	UpdateLocation = 92, 
	DeleteLocation = 93, 
	ReadContact = 100, 
	CreateContact = 101, 
	UpdateContact = 102, 
	DeleteContact = 103, 
	ReadContactGroup = 110, 
	CreateContactGroup = 111, 
	UpdateContactGroup = 112, 
	DeleteContactGroup = 113, 
	ReadAddress = 120, 
	CreateAddress = 121, 
	UpdateAddress = 123, 
	DeleteAddress = 124, 
	ReadNote = 130, 
	CreateNote = 131, 
	UpdateNote = 132, 
	DeleteNote = 133, 
	ReadRole = 140, 
	CreateRole = 141, 
	UpdateRole = 142, 
	DeleteRole = 143, 
	ReadAppPermissions = 150, 
	ReadTenant = 160, 
	CreateTenant = 161, 
	UpdateTenant = 162, 
	DeleteTenant = 163, 
	ReadLoginProvider = 170, 
	CreateLoginProvider = 171, 
	UpdateLoginProvider = 172, 
	DeleteLoginProvider = 173, 
	ReadDevice = 190, 
	CreateDevice = 191, 
	UpdateDevice = 192, 
	DeleteDevice = 193, 
	UsePinLogin = 200, 
	UsePushRegister = 201, 
	UseDeviceRegister = 202, 
	UseExternalAppLogin = 203, 
	UseBadgeLogin = 204,
	ReadLocationGroup = 210, 
	CreateLocationGroup = 211, 
	UpdateLocationGroup = 212, 
	DeleteLocationGroup = 213, 
	ReadInfo = 230, 
	CreateInfo = 231, 
	UpdateInfo = 232, 
	DeleteInfo = 233, 
	ReadTask = 240, 
	CreateTask = 241, 
	UpdateTask = 242, 
	DeleteTask = 243, 
	ReadForm = 250, 
	CreateForm = 251, 
	UpdateForm = 252, 
	DeleteForm = 253, 
	ReadFilledForm = 260, 
	CreateFilledForm = 261, 
	UpdateFilledForm = 262, 
	DeleteFilledForm = 263, 
	ReadChat = 270, 
	CreateChat = 271, 
	UpdateChat = 272, 
	DeleteChat = 273, 
	UseEmailPerChat = 274, 
	UseChatWithoutCloak = 275, 
	ReadOverview = 280, 
	ReadKnowledgeArticle = 290, 
	CreateKnowledgeArticle = 291, 
	UpdateKnowledgeArticle = 292, 
	DeleteKnowledgeArticle = 293, 
	ReadHolidaySet = 300, 
	CreateHolidaySet = 301, 
	UpdateHolidaySet = 302, 
	DeleteHolidaySet = 303, 
	ReadFile = 310, 
	CreateFile = 311, 
	UpdateFile = 312, 
	DeleteFile = 313, 
	ReadLoginChallenge = 320, 
	CreateLoginChallenge = 321, 
	UpdateLoginChallenge = 322, 
	DeleteLoginChallenge = 323, 
	ReadFilledFormField = 330, 
	CreateFilledFormField = 331, 
	UpdateFilledFormField = 332, 
	DeleteFilledFormField = 333, 
	ReadFormRow = 340, 
	CreateFormRow = 341, 
	UpdateFormRow = 342, 
	DeleteFormRow = 343, 
	ReadFormField = 350, 
	CreateFormField = 351, 
	UpdateFormField = 352, 
	DeleteFormField = 353, 
	ReadAttachment = 360, 
	CreateAttachment = 361, 
	UpdateAttachment = 362, 
	DeleteAttachment = 363, 
	ReadExecution = 370, 
	CreateExecution = 371, 
	UpdateExecution = 372, 
	DeleteExecution = 373, 
	ReadImpact = 380, 
	CreateImpact = 381, 
	UpdateImpact = 382, 
	DeleteImpact = 383, 
	ReadCondition = 390, 
	CreateCondition = 391, 
	UpdateCondition = 392, 
	DeleteCondition = 393, 
	ReadChatMessage = 400, 
	CreateChatMessage = 401, 
	UpdateChatMessage = 402, 
	DeleteChatMessage = 403, 
	ReadChatMember = 410, 
	CreateChatMember = 411, 
	UpdateChatMember = 412, 
	DeleteChatMember = 413, 
	ReadChatMemberMessage = 420, 
	CreateChatMemberMessage = 421, 
	UpdateChatMemberMessage = 422, 
	DeleteChatMemberMessage = 423, 
	ReadHoliday = 430, 
	CreateHoliday = 431, 
	UpdateHoliday = 432, 
	DeleteHoliday = 433, 
	ReadHistory = 440, 
	CreateHistory = 441, 
	UpdateHistory = 442, 
	DeleteHistory = 443, 
	ReadTaskJob = 450, 
	CreateTaskJob = 451, 
	UpdateTaskJob = 452, 
	DeleteTaskJob = 453, 
	ReadTaskJobStatus = 460, 
	CreateTaskJobStatus = 461, 
	UpdateTaskJobStatus = 462, 
	DeleteTaskJobStatus = 463, 
	ReadFolderAccess = 470, 
	CreateFolderAccess = 471, 
	UpdateFolderAccess = 472, 
	DeleteFolderAccess = 473, 
	UseAllFolderAccess = 474, 
	ReadTaskJobStateReasonSet = 480, 
	CreateTaskJobStateReasonSet = 481, 
	UpdateTaskJobStateReasonSet = 482, 
	DeleteTaskJobStateReasonSet = 483, 
	ReadTaskJobStateReason = 490, 
	CreateTaskJobStateReason = 491, 
	UpdateTaskJobStateReason = 492, 
	DeleteTaskJobStateReason = 493, 
	ReadPing = 500, 
	CreatePing = 501, 
	UpdatePing = 502, 
	DeletePing = 503, 
	ReadMe = 520, 
	ReadProductSubscription = 530, 
	CreateProductSubscription = 531, 
	UpdateProductSubscription = 532, 
	DeleteProductSubscription = 533, 
	UseExpiredSubscription = 535, 
	UseAdmin = 540, 
	ReadGraph = 550, 
	ReadMessagePattern = 560, 
	CreateMessagePattern = 561, 
	UpdateMessagePattern = 562, 
	DeleteMessagePattern = 563, 
	ReadMessagePatternElement = 570, 
	CreateMessagePatternElement = 571, 
	UpdateMessagePatternElement = 572, 
	DeleteMessagePatternElement = 573, 
	ReadDateTimePattern = 580, 
	CreateDateTimePattern = 581, 
	UpdateDateTimePattern = 582, 
	DeleteDateTimePattern = 583, 
	ReadFormValue = 590, 
	CreateFormValue = 591, 
	UpdateFormValue = 592, 
	DeleteFormValue = 593, 
	ReadPlannedTime = 600, 
	CreatePlannedTime = 601, 
	UpdatePlannedTime = 602, 
	DeletePlannedTime = 603, 
	ReadTaskJobCondition = 610, 
	CreateTaskJobCondition = 611, 
	UpdateTaskJobCondition = 612, 
	DeleteTaskJobCondition = 613, 
	ReadCustomValue = 620, 
	CreateCustomValue = 621, 
	UpdateCustomValue = 622, 
	DeleteCustomValue = 623, 
	ReadCustomPropertyValue = 630, 
	CreateCustomPropertyValue = 631, 
	UpdateCustomPropertyValue = 632, 
	DeleteCustomPropertyValue = 633, 
	ReadSettingValue = 640, 
	CreateSettingValue = 641, 
	UpdateSettingValue = 642, 
	DeleteSettingValue = 643, 
	ReadState = 650, 
	CreateState = 651, 
	UpdateState = 652, 
	DeleteState = 653, 
	ReadStateMachine = 660, 
	CreateStateMachine = 661, 
	UpdateStateMachine = 662, 
	DeleteStateMachine = 663, 
	ReadStateTransition = 670, 
	CreateStateTransition = 671, 
	UpdateStateTransition = 672, 
	DeleteStateTransition = 673, 
	ReadDataTable = 680, 
	CreateDataTable = 681, 
	UpdateDataTable = 682, 
	DeleteDataTable = 683, 
	ReadDataTableColumn = 690, 
	CreateDataTableColumn = 691, 
	UpdateDataTableColumn = 692, 
	DeleteDataTableColumn = 693, 
	ReadDashboard = 700, 
	CreateDashboard = 701, 
	UpdateDashboard = 702, 
	DeleteDashboard = 703, 
	ReadSolution = 710, 
	CreateSolution = 711, 
	UpdateSolution = 712, 
	DeleteSolution = 713, 
	ReadSolutionItem = 720, 
	CreateSolutionItem = 721, 
	UpdateSolutionItem = 722, 
	DeleteSolutionItem = 723, 
	ReadDataTableFilter = 730, 
	CreateDataTableFilter = 731, 
	UpdateDataTableFilter = 732, 
	DeleteDataTableFilter = 733, 
	ReadPrioritySet = 740, 
	CreatePrioritySet = 741, 
	UpdatePrioritySet = 742, 
	DeletePrioritySet = 743, 
	ReadPriority = 750, 
	CreatePriority = 751, 
	UpdatePriority = 752, 
	DeletePriority = 753, 
	ReadGuestCard = 760, 
	CreateGuestCard = 761, 
	UpdateGuestCard = 762, 
	DeleteGuestCard = 763, 
	ReadGuestCardBooking = 770, 
	CreateGuestCardBooking = 771, 
	UpdateGuestCardBooking = 772, 
	DeleteGuestCardBooking = 773, 
	ReadSolutionTemplate = 780, 
	CreateSolutionTemplate = 781, 
	UpdateSolutionTemplate = 782, 
	DeleteSolutionTemplate = 783, 
	ReadEscalationLevel = 790, 
	CreateEscalationLevel = 791, 
	UpdateEscalationLevel = 792, 
	DeleteEscalationLevel = 793, 
	ReadEscalationRule = 800, 
	CreateEscalationRule = 801, 
	UpdateEscalationRule = 802, 
	DeleteEscalationRule = 803, 
	ReadTicketEvent = 810, 
	CreateTicketEvent = 811, 
	UpdateTicketEvent = 812, 
	DeleteTicketEvent = 813, 
	ReadAudit = 820, 
	ReadMission = 830, 
	CreateMission = 831, 
	UpdateMission = 832, 
	DeleteMission = 833, 
	UpdateActualMissionDates = 834, 
	ReadLink = 850, 
	CreateLink = 851, 
	UpdateLink = 852, 
	DeleteLink = 853, 
	ReadLinkedElement = 860, 
	CreateLinkedElement = 861, 
	UpdateLinkedElement = 862, 
	DeleteLinkedElement = 863, 
	ReadAttachmentForTech = 870, 
	CreateAttachmentForTech = 871, 
	UpdateAttachmentForTech = 872, 
	DeleteAttachmentForTech = 873, 
	ReadMailBox = 880, 
	CreateMailBox = 881, 
	UpdateMailBox = 882, 
	DeleteMailBox = 883, 
	ReadTemplate = 890, 
	CreateTemplate = 891, 
	UpdateTemplate = 892, 
	DeleteTemplate = 893, 
	ReadDocumentTemplate = 900, 
	CreateDocumentTemplate = 901, 
	UpdateDocumentTemplate = 902, 
	DeleteDocumentTemplate = 903, 
	DisplayBackend = 10000, 
	DisplayMasterData = 10100, 
	DisplayContacts = 10101, 
	DisplayContactGroups = 10102, 
	DisplayLocations = 10103, 
	DisplayLocationGroups = 10104, 
	DisplayForms = 10105, 
	DisplayTasks = 10106, 
	DisplayInfos = 10107, 
	DisplayKnowledge = 10108, 
	DisplayDevices = 10109, 
	DisplayChats = 10110, 
	DisplayDocuments = 10111, 
	DisplayHolidays = 10112, 
	DisplayDashboard = 10113, 
	DisplayManual = 10114, 
	DisplayAddresses = 10115, 
	DisplayCompanies = 10116, 
	DisplayRole = 10117, 
	DisplayProductSubscription = 10118, 
	DisplayStereoTypes = 10119, 
	DisplaySettings = 10120, 
	DisplayResources = 10121, 
	DisplayTickets = 10122, 
	DisplayStateMachines = 10123, 
	DisplayDataTables = 10124, 
	DisplayDataDashboard = 10125, 
	DisplayPriorities = 10126, 
	DisplayGuestCard = 10127, 
	DisplayEscalations = 10128, 
	DisplayMissions = 10129, 
	DisplayAppDashboard = 10201, 
	DisplayAppInfos = 10202, 
	DisplayAppTasks = 10203, 
	DisplayAppTaskArchive = 10204, 
	DisplayAppKnowledge = 10205, 
	DisplayAppDocuments = 10206, 
	DisplayAppChats = 10207,


	UseGetMeTimeline = 1400,

}
export enum ContextKind { 
	Global = 1, 
	Location = 2, 
	Tenant = 3, 
	LocationGroup = 4
}
