import {Injectable} from '@angular/core';

declare var YT;

@Injectable({
    providedIn: 'root'
})
export class YoutubePlayerService {

    private players: any[] = [];
    public isPlaying = false;

    public init() {
        const script = document.createElement('script');
        script.src = 'https://www.youtube.com/player_api';
        document.head.appendChild(script);
    }

    private onStateChange() {
        const states = this.players.map(x => x?.getPlayerState());

        this.isPlaying = states.some(x => x === 1);
    }

    public detectPlayers() {
        setTimeout(() => {
            const elements = Array.from(window.document.querySelectorAll('iframe[src^="https://www.youtube.com/embed/"]'));
            this.players = elements.map(e => new YT.Player(e));

            this.players.forEach(player => {
                player?.addEventListener('onStateChange', () => this.onStateChange());
            });

        }, 1000);
    }
}
