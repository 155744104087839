import * as jwt_decode from "jwt-decode";
import { environment } from "../../../../environments/environment";
import { PermissionAreaDto, TokenDto } from "../../server/DataTransferObject/Objects/Authentication";
import { Identity } from "../auth/identity";
import { IdentityResult } from "../auth/identity-result";
import { ContextPart } from "../../server/Enums";
import { ContextKind } from "../../server/index-enums";

export class TokenHelper {

    public static decodeToken(token: string) {
        return jwt_decode(token);
    }

    public static getIdentityFromAppToken(appToken: TokenDto, forceSingleContext: boolean = true) {
        const currentTenants = appToken.restrictedRoleIds.length <= 0 
            ? appToken.info.tenants
            : appToken.info.tenants.filter(t => t.roles.some(r => appToken.restrictedRoleIds.some(rr => rr == r.roleId)));

        if(currentTenants.length > 1) {
            console.error("TOKEN WITH MULTIPLE TENANTS DETECTED!");
            throw new Error("identity cannot contain more than one tenant");
        }
        
        const identity = new Identity(currentTenants[0].displayName, appToken.token, appToken.refreshToken);
        const context = TokenHelper.getContextFromToken(appToken, identity);

        if(context == null) {
            if(!forceSingleContext)
                return new IdentityResult(identity, currentTenants[0].tenantId, null);
                
            console.error("TOKEN WITH NOT A SINGLE CONTEXT DETECTED!");
            throw new Error("identity cannot contain more than one tenant or context");
        }

        return new IdentityResult(identity, context.tenantId, context.locationId);
    }

    private static getContextFromToken(appToken: TokenDto, identity: Identity) {
        const decoded = this.decodeToken(appToken.token);
        const areas: PermissionAreaDto[] = JSON.parse(decoded.permissions);
        const isSingleContext = areas.length == 1 && areas[0].contexts.length == 1;

        if (isSingleContext) {
            const context = areas[0].contexts[0];
            const isContextLocation = context.type == ContextKind.Location;

            if (isContextLocation) {
                return {
                    tenantId: context.tnt,
                    locationId: context.src
                };
            }
        }

        return null;
    }

    public static getTokenExpirationDate(token: string): Date {
        const decoded = jwt_decode(token);

        if (decoded.exp === undefined) return null;

        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
    }

    public static isTokenExpired(token: string): boolean {
        if (!token) return true;

        const date = this.getTokenExpirationDate(token);
        if (date === undefined) return false;
        TokenHelper.AddExpireOffset(date);
        return !(date.valueOf() >= new Date().valueOf());
    }

    private static AddExpireOffset(date: Date) {
        date.setSeconds(date.getSeconds() - environment.apiUrl.tokenExpireOffsetSec);
    }
}