import { HttpResponse } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Device } from '@ionic-native/device/ngx';
import { Storage } from '@ionic/storage';
import { AuthenticationData, Guid, Identity, StorageKeys, TokenHelper } from 'models/client';
import { TokenDto } from '../../../models/server/DataTransferObject/Objects/Authentication';
import { RegisterDeviceByLocationDto } from '../../../models/server/DataTransferObject/Objects/Devices';
import { PermissionService } from '../permission/permission.service';
import { DeviceRegisterService } from './device-register.service';
import { LoginService } from './login.service';
import { RegisterDeviceDto } from '../../../models/server/Nexnox/Core/Shared/DataTransferObject/Objects/Devices/RegisterDeviceDto';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private authenticationData: AuthenticationData = null;

  constructor(
    private storage: Storage,
    private device: Device,
    private loginService: LoginService,
    private zone: NgZone,
    private router: Router,
    private deviceRegisterService: DeviceRegisterService,
    private permissionService: PermissionService
  ) { 
    this.init();
  }

  async init() {
    await this.load();
  }

  async load(force: boolean = false) {
    if(this.authenticationData && force == false) {
      await this.loadDeviceId();
      return;
    }

    let data = await this.storage.get(StorageKeys.AuthenticationService);

    if(data) {
      this.authenticationData = JSON.parse(data) || new AuthenticationData();
    } else {
      this.authenticationData = new AuthenticationData();
    }

    await this.loadDeviceId();
  }

  async save() {
    const data = JSON.stringify(this.authenticationData);
    await this.storage.set(StorageKeys.AuthenticationService, data);
  }

  public isRegistered() {
    return this.authenticationData && this.authenticationData.identity && this.authenticationData.identity.token.length > 0;
  }

  public async loginWithUser(login: string, password: string) {
    const loginResponse = await this.loginService.loginWithPassword(login, password);
    const firstToken = loginResponse.body.token;
    const userIdentity = new Identity(login, firstToken);
    return {
      identity: userIdentity,
      info: loginResponse.body.info
    };
  }

  public async loginWithDeviceRegister(registratorUserIdentity: Identity, deviceName: string, locationId: number) {
    const dto = new RegisterDeviceByLocationDto();
    dto.deviceName = deviceName;
    dto.locationId = locationId;
    dto.hardwareId = this.authenticationData.deviceId;
    
    const registerResponse = await this.deviceRegisterService.registerByLocation(registratorUserIdentity, dto);
    await this.loginApi(registerResponse);
    return true;
  }

  public async loginWithCode(code: string) : Promise<boolean> {
    const dto = new RegisterDeviceDto();
    dto.code = code;
    dto.hardwareId = this.authenticationData.deviceId;

    const loginResponse = await this.loginService.loginWithDevicePin(dto);
    await this.loginApi(loginResponse);
    return true;
  }

  public async loginWithRefreshToken() : Promise<boolean> {
    const loginResponse = await this.loginService.loginRefresh(this.authenticationData.refreshToken);
    await this.loginApi(loginResponse);
    return true;
  }

  public async logout(navigate: boolean = true) {
    this.logoutApp();
    await this.save();

    if(navigate) {
      await this.zone.run(async _ => await this.router.navigate(['/']));
    }
  }

  public async getDeviceId() : Promise<string> {
    await this.load();
    return this.authenticationData.deviceId;
  }

  public getIdentity() : Identity {
    return this.authenticationData.identity;
  }
  public getTenantId() : number {
    return this.authenticationData.tenantId;
  }
  public getLocationId() : number {
    return this.authenticationData.locationId;
  }

  private async loginApi(loginResponse: HttpResponse<TokenDto>) {
    await this.loginApp(loginResponse.body);
    await this.save();
  }

  private logoutApp() {
    this.authenticationData.identity = null;
    this.authenticationData.refreshToken = null;
    this.authenticationData.tenantId = null;
  }

  private async loginApp(appToken: TokenDto) {
    this.authenticationData.identity = null;
    
    if(appToken.info.tenants.length > 1) {
      const firstTenantRoles = appToken.info.tenants[0].roles.map(x => x.roleId);
      const restrictedResponse = await this.loginService.loginRefresh(this.authenticationData.refreshToken, firstTenantRoles);
      
      appToken = restrictedResponse.body;
    }

    const identityResult = TokenHelper.getIdentityFromAppToken(appToken);

    if(!identityResult) {
      throw new Error("identity not found"); //TODO better exception, show user the error
    } else {
      this.authenticationData.identity = identityResult.identity;
      this.authenticationData.tenantId = identityResult.tenantId;
      this.authenticationData.locationId = identityResult.locationId;
    }
    
    this.authenticationData.refreshToken = appToken.refreshToken;

    await this.permissionService.loadUserPermissionsFromToken(appToken.token);
  }


  private async loadDeviceId() {
    if(this.authenticationData.deviceId && this.authenticationData.deviceId.length > 0) {
      return;
    }

    try
    {
      this.authenticationData.deviceId = this.device.uuid;
    }
    catch(error) {
      console.error("cannot get device id: ", error);
    }
    finally {
      if(!this.authenticationData.deviceId || this.authenticationData.deviceId.length <= 0) {
        this.authenticationData.deviceId = Guid.NewGuid();
      } 
    }

    await this.save();
  }

}
