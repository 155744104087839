import { Component } from '@angular/core';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { InitService } from './services/ui/init.service';
import { DeviceService } from './services/ui/device/device.service';
import { VersionService } from './services/api/version/version.service';
import { Router } from '@angular/router';
import { ModalService } from './services/ui/modal.service';
import { Deeplinks, DeeplinkMatch } from '@ionic-native/deeplinks/ngx';
import { AppModeService } from './services/ui/appmode.service';
import { ExternalCaller } from './models/client';
import { PlatformService } from './services/ui/device/platform.service';
import {YoutubePlayerService} from './services/ui/youtube-player.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateService,
    private initService: InitService,
    private device: DeviceService,
    private versionService: VersionService,
    private router: Router,
    private modalService: ModalService,
    private deeplinks: Deeplinks,
    private appModeService: AppModeService,
    private platformService: PlatformService,
    private youtubePlayerService: YoutubePlayerService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString(getComputedStyle(document.documentElement).getPropertyValue("--ion-color-primary"));

      this.splashScreen.hide();

      this.translate.addLangs(['en-US', 'de-DE', 'pl-PL']);
      this.translate.setDefaultLang(environment.lang.defaultLanguage);

      if (environment.lang.useBrowserLanguage == true) {
        this.translate.use(this.detectLang());
      } else {
        this.translate.use(environment.lang.defaultLanguage);
      }

      this.subscribeDeeplinks();

      this.checkAppVersion()
        .then(_ => this.initService.init())
        .catch(_ => this.router.navigate(['/version-error'], { replaceUrl: true }));

      this.youtubePlayerService.init();
    });
  }

  private detectLang() {
    const browserLang = this.translate.getBrowserLang();
    const detectedLang = browserLang.match(/en|de|pl/) ? browserLang : environment.lang.defaultLanguage;

    if (detectedLang == 'de')
      return 'de-DE';
    if (detectedLang == 'en')
      return 'en-US';
    if (detectedLang == 'pl')
      return 'pl-PL';

    return detectedLang;
  }

  public subscribeDeeplinks() {
    if (!this.platformService.isNative())
      return;

    this.deeplinks.route({}).subscribe(_ => false, (nomatch: DeeplinkMatch) => {
      let activatedRouteLink: string = (nomatch.$link.path || '/');

      if (!activatedRouteLink.startsWith('/'))
        activatedRouteLink = '/' + activatedRouteLink;

      if (!activatedRouteLink.endsWith('/'))
        activatedRouteLink = activatedRouteLink + '/';

      activatedRouteLink = activatedRouteLink.replace(/^(\/app)?(\/orga)/, "");

      try {
        this.handleDeeplink(activatedRouteLink);
      } catch (error) {
        console.error("could not handle deeplink: ", error);
      }

      this.subscribeDeeplinks();
    });
  }

  public handleDeeplink(link: string) {
    switch (link) {
      case '/caller/shopiq/':
        this.appModeService.isCalledFromExternal(ExternalCaller.ShopIQ);
        return;

      default:
        return;
    }
  }

  private async checkAppVersion() {
    let appVersion = await this.device.getAppVersion();
    let isAppVersionSupported = true;
    const loader: HTMLIonLoadingElement = await this.modalService.createLoader();

    if (appVersion) {
      try {
        loader.present()
        isAppVersionSupported = await this.versionService.checkApp(appVersion);
      } catch (error) {
        console.error("App Version could not be verified: ", error);
      } finally {
        loader.dismiss();
      }

      if (!isAppVersionSupported) {
        throw new Error("App Version not supported");
      }
    } else {
      console.warn("App Version could not be verified");
    }
  }
}
